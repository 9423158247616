<template>
  <div class="page page-home text-center pt-lg-4">
    <div class="page-body">
      <div class="pt-5 pt-lg-5 pb-3 pt-lg-4">
        <a href="https://onmeeting.com.vn" class="logo">
          <img src="/img/logo.svg" alt="OnMeeting" class="img-logo"/>
        </a>
      </div>
      <div class="form-join">
        <div style="font-size: 15px; font-weight: 500; margin-bottom: 15px">
          {{ $t("Hi {0}, please set your new password for login", [this.formData.fullName]) }}
        </div>
        <ValidationObserver ref="form" tag="form" class="form" @submit.prevent="onSubmitClick()">
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }" vid="password" :name="$t('Password')">
              <label>{{ $t("Password") }}</label>
              <b-input type="password" v-model="formData.password" :placeholder="$t('Password')"
                       :disabled="loading"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }">
              <label>{{ $t("Confirm password") }}</label>
              <b-input type="password" v-model="formData.confirmPassword" :placeholder="$t('Confirm password')"
                       :disabled="loading"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <b-button type="submit" variant="warning" block size="lg" class="font-weight-bold"
                      :disabled="loading">
              {{ $t("Save") }}
            </b-button>
          </b-form-group>
        </ValidationObserver>
      </div>
    </div>
    <div class="page-footer">
      {{ $t("Developed by FPT Telecom International 2022.") }} <img src="/img/logo-fpt.png" alt="FPT Telecom"
                                                                    class="logo-fpt"/>
    </div>

    <b-overlay no-wrap :show="loading" style="z-index: 9999">
      <b-spinner/>
    </b-overlay>
  </div>
</template>

<script>
import authenticateService from "../services/authenticateService";
import dialogHelper from "../helpers/dialogHelper";

export default {
  name: "RecoveryPassword",
  data() {
    return {
      loading: false,
      formData: {
        fullName: "",
        accessToken: "",
        password: "",
        confirmPassword: ""
      }
    }
  },
  created() {
    const query = this.$route.query;
    this.formData.fullName = query.fullName;
    this.formData.accessToken = query.accessToken;
    console.log(this.formData);
  },
  methods: {
    async onSubmitClick() {
      const valid = await this.$refs.form.validate()
      if (!valid)
        return

      this.loading = true;
      const response = await authenticateService.changePasswordByToken(this.formData);
      this.loading = false;
      if (!response) {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection.")
        return

      }

      if (response.error) {
        await dialogHelper.alert(response.message)
        return
      }

      await dialogHelper.alert(response.message, 'success');

      await this.$router.push({name: "Home"});
    }
  }
}
</script>

<style scoped>

</style>
