<template>
  <div class="page page-meeting">
  </div>
</template>

<script>
export default {
  name: "Meeting",
  data() {
    return {
      meetingData: null
    }
  },
  created() {
    this.meetingData = this.$store.getters.meetingData
    if (!this.meetingData) {
      location.href = location.origin;
      //this.$router.push({name: "Home"})
      return;
    }

    this.$store.dispatch("setMeetingData", null);

  },
  mounted() {
    document.getElementById("zmmtg-root").style.display = "block"
    const self = this;
    const meetingData = self.meetingData;
    if (!meetingData)
      return;

    this.ZoomMtg.init({
      leaveUrl: location.origin,
      success: function () {
        self.ZoomMtg.join({
          meetingNumber: meetingData.meetingID,
          userName: meetingData.displayName,
          signature: meetingData.signature2,
          sdkKey: "ahRfCbnR0oEHiJrTchZm3nUuwmkAVRsowcHb",
          userEmail: "",
          passWord: meetingData.password,
          success: function (res) {
            console.log("success", res);
          },
          error: function (res) {
            console.log("error", res);
          },
        })
      }
    });
  }
}
</script>

<style scoped lang="scss">
.page-meeting {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
